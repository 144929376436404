@charset "UTF-8";
/**
 * LIBRARY PREFIX.
 */
/**
 * Vendor.
 */
/*
* Scut, a collection of Sass utilities
* to ease and improve our implementations of common style-code patterns.
* v1.4.0
* Docs at http://davidtheclark.github.io/scut
*/
/**
 * Settings.
 */
/**
 * Utility colors.
 */
/**
 * Greys.
 */
/**
 * Text.
 */
/**
 * Brand.
 */
/**
 * Alert.
 */
/**
 * Typographic Grid baseline.
 */
/**
 * Global Spacing
 * Should be related to typographic baseline $default-grid-height.
 */
/**
 * Global device breakpoints.
 */
/**
 * Global constrained.
 */
/**
 * Global grid settings.
 */
/**
 * Grid area map (col, col-span, row, row-span).
 */
/**
 * Fonts.
 */
/**
 * Font, line-height, and color for body text, headings, and more.
 */
/**
 * Typography.
 */
/**
 * Font-sizes.
 */
/**
 * Heading font-sizes.
 */
/**
 * Bespoke typography config.
 */
/**
 * Font Family baseline.
 */
/**
 * Alerts.
 */
/**
 * Links.
 */
/**
 * Forms colors.
 */
/**
 * Form states.
 */
/**
 * Gradient color stops.
 */
/**
 * Gradients setup.
 */
/**
 * Variables.
 */
/**
 * LIBRARY PREFIX.
 */
/**
 * Vendor.
 */
/*
* Scut, a collection of Sass utilities
* to ease and improve our implementations of common style-code patterns.
* v1.4.0
* Docs at http://davidtheclark.github.io/scut
*/
/**
 * Settings.
 */
/**
 * Utility colors.
 */
/**
 * Greys.
 */
/**
 * Text.
 */
/**
 * Brand.
 */
/**
 * Alert.
 */
/**
 * Typographic Grid baseline.
 */
/**
 * Global Spacing
 * Should be related to typographic baseline $default-grid-height.
 */
/**
 * Global device breakpoints.
 */
/**
 * Global constrained.
 */
/**
 * Global grid settings.
 */
/**
 * Grid area map (col, col-span, row, row-span).
 */
/**
 * Fonts.
 */
/**
 * Font, line-height, and color for body text, headings, and more.
 */
/**
 * Typography.
 */
/**
 * Font-sizes.
 */
/**
 * Heading font-sizes.
 */
/**
 * Bespoke typography config.
 */
/**
 * Font Family baseline.
 */
/**
 * Alerts.
 */
/**
 * Links.
 */
/**
 * Forms colors.
 */
/**
 * Form states.
 */
/**
 * Gradient color stops.
 */
/**
 * Gradients setup.
 */
/**
 * Variables.
 */
/**
 * UI.
 */
/**
 * Fill types.
 */
/**
 * Forms.
 */
/**
 * Component settings.
 */
/**
 * Base Fonts map.
 *
 * breakpoint: (font-size, line-height, leading-space, trailing-space)
 */
/**
 * Description.
 */
/**
 * Copy.
 */
/**
 * Settings.
 */
/**
 * To use CSS filters as the hover effect instead of opacity,
 * set $hamburger-hover-use-filter as true and
 * change the value of $hamburger-hover-filter accordingly.
 */
/**
 * Types (Remove or comment out what you don’t need)
 */
/**
 * Buttons
 *
 * For each of BASE's buttons,
 * define text, background and
 * border color.
 */
/**
 * Button sizes map
 * $padding-horizontal, $font-size, $border-radius, $grid-height-setting, $btn-border-size, $btn-margin, $btn-padding
 */
/**
 * Button fills map
 */
/**
 * Iconography.
 */
/**
 * Exportable mixins.
 */

.element-hidden {
  display: none; }

.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute; }

.element-invisible {
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute; }

/**
 * Used for hr border to prevent breaking vertical rhythm.
 */
/**
 * Inline list spacing.
 */
/**
 * Helper functions.
 */
/**
 * Vertical Rhythm.
 */
/**
 * Inverted copy.
 */
/**
/* Font mixins.
 */
/**
 * Grid repeat function
 */
/**
 * grid-column mixin
 */
/**
 * grid-row mixin
 */
/**
 * grid-cell mixin
 */
/**
 * grid-area mixin
 */
/**
 * grid mixin
 */
/**
 * Default grid mixin
 * $cols number of grid columns for cssgrid().
 * $colscount number of columns for IE11 hack. TODO debug why $cols cant be used.
 * $row-gap grid-row-gap value.
 * $col-gap grid-column-gap value.
 */
/**
 * Button variants
 *
 * Easily pump out default styles, as well as :hover, :focus, :active,
 * and disabled options for all buttons.
 */
/**
   $icon-name : Must NOT be  a string/wrapped in quotes.
   $fill-colour : expecting hex or rgb value.
**/
/**
 * Button link variants
 *
 * Easily pump out default styles, as well as :hover, :focus, :active,
 * and disabled options for all buttons.
 */
/**
 * Button tab
 */
/**
 * Button sizes.
 */
/**
 * UI.
 */
/**
 * Fill types.
 */
/**
 * Forms.
 */
/**
 * Component settings.
 */
/**
 * Base Fonts map.
 *
 * breakpoint: (font-size, line-height, leading-space, trailing-space)
 */
/**
 * Description.
 */
/**
 * Copy.
 */
/**
 * Settings.
 */
/**
 * To use CSS filters as the hover effect instead of opacity,
 * set $hamburger-hover-use-filter as true and
 * change the value of $hamburger-hover-filter accordingly.
 */
/**
 * Types (Remove or comment out what you don’t need)
 */
/**
 * Buttons
 *
 * For each of BASE's buttons,
 * define text, background and
 * border color.
 */
/**
 * Button sizes map
 * $padding-horizontal, $font-size, $border-radius, $grid-height-setting, $btn-border-size, $btn-margin, $btn-padding
 */
/**
 * Button fills map
 */
/**
 * Iconography.
 */
.wrapper {
  margin-top: 0;
  overflow: hidden;
  position: relative; }

.imagelayout {
  overflow: hidden;
  position: relative; }
  .imagelayout::after {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.75));
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.default {
  min-height: 50vh; }

.narrow {
  min-height: 40vh; }

.contentlayout {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1; }

.spacing {
  max-width: 75%;
  text-align: center; }
  @supports (display: grid) {
    .spacing {
      /** if cols or rows are numbers convert to fraction lists */
      /**
     * ie does not support grid gap - why we insert the gap space as a row or colum
     * note! the first loop is to ensure cols/rows are not a multidimensional list
     * (when generated by the repeat function)
     */
      display: -ms-grid;
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem; } }
  .spacing::before, .spacing::after {
    display: none; }
  @media (min-width: 48em) {
    .spacing {
      margin-top: 256px;
      max-width: 95%; } }

.image {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%; }

.imagetitle {
  color: #fff;
  margin-bottom: 16px;
  position: relative;
  z-index: 1; }

.imagetagline {
  font-family: 'Cormorant Garamond Regular' !important;
  font-weight: normal !important; }
