@charset "UTF-8";
/**
 * LIBRARY PREFIX.
 */
/**
 * Vendor.
 */
/*
* Scut, a collection of Sass utilities
* to ease and improve our implementations of common style-code patterns.
* v1.4.0
* Docs at http://davidtheclark.github.io/scut
*/
/**
 * Settings.
 */
/**
 * Utility colors.
 */
/**
 * Greys.
 */
/**
 * Text.
 */
/**
 * Brand.
 */
/**
 * Alert.
 */
/**
 * Typographic Grid baseline.
 */
/**
 * Global Spacing
 * Should be related to typographic baseline $default-grid-height.
 */
/**
 * Global device breakpoints.
 */
/**
 * Global constrained.
 */
/**
 * Global grid settings.
 */
/**
 * Grid area map (col, col-span, row, row-span).
 */
/**
 * Fonts.
 */
/**
 * Font, line-height, and color for body text, headings, and more.
 */
/**
 * Typography.
 */
/**
 * Font-sizes.
 */
/**
 * Heading font-sizes.
 */
/**
 * Bespoke typography config.
 */
/**
 * Font Family baseline.
 */
/**
 * Alerts.
 */
/**
 * Links.
 */
/**
 * Forms colors.
 */
/**
 * Form states.
 */
/**
 * Gradient color stops.
 */
/**
 * Gradients setup.
 */
/**
 * Variables.
 */
/**
 * LIBRARY PREFIX.
 */
/**
 * Vendor.
 */
/*
* Scut, a collection of Sass utilities
* to ease and improve our implementations of common style-code patterns.
* v1.4.0
* Docs at http://davidtheclark.github.io/scut
*/
/**
 * Settings.
 */
/**
 * Utility colors.
 */
/**
 * Greys.
 */
/**
 * Text.
 */
/**
 * Brand.
 */
/**
 * Alert.
 */
/**
 * Typographic Grid baseline.
 */
/**
 * Global Spacing
 * Should be related to typographic baseline $default-grid-height.
 */
/**
 * Global device breakpoints.
 */
/**
 * Global constrained.
 */
/**
 * Global grid settings.
 */
/**
 * Grid area map (col, col-span, row, row-span).
 */
/**
 * Fonts.
 */
/**
 * Font, line-height, and color for body text, headings, and more.
 */
/**
 * Typography.
 */
/**
 * Font-sizes.
 */
/**
 * Heading font-sizes.
 */
/**
 * Bespoke typography config.
 */
/**
 * Font Family baseline.
 */
/**
 * Alerts.
 */
/**
 * Links.
 */
/**
 * Forms colors.
 */
/**
 * Form states.
 */
/**
 * Gradient color stops.
 */
/**
 * Gradients setup.
 */
/**
 * Variables.
 */
/**
 * UI.
 */
/**
 * Fill types.
 */
/**
 * Forms.
 */
/**
 * Component settings.
 */
/**
 * Base Fonts map.
 *
 * breakpoint: (font-size, line-height, leading-space, trailing-space)
 */
/**
 * Description.
 */
/**
 * Copy.
 */
/**
 * Settings.
 */
/**
 * To use CSS filters as the hover effect instead of opacity,
 * set $hamburger-hover-use-filter as true and
 * change the value of $hamburger-hover-filter accordingly.
 */
/**
 * Types (Remove or comment out what you don’t need)
 */
/**
 * Buttons
 *
 * For each of BASE's buttons,
 * define text, background and
 * border color.
 */
/**
 * Button sizes map
 * $padding-horizontal, $font-size, $border-radius, $grid-height-setting, $btn-border-size, $btn-margin, $btn-padding
 */
/**
 * Button fills map
 */
/**
 * Iconography.
 */
/**
 * Exportable mixins.
 */

.element-hidden {
  display: none; }

.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute; }

.element-invisible {
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  height: 1px;
  margin: 0;
  overflow: hidden;
  position: absolute; }

/**
 * Used for hr border to prevent breaking vertical rhythm.
 */
/**
 * Inline list spacing.
 */
/**
 * Helper functions.
 */
/**
 * Vertical Rhythm.
 */
/**
 * Inverted copy.
 */
/**
/* Font mixins.
 */
/**
 * Grid repeat function
 */
/**
 * grid-column mixin
 */
/**
 * grid-row mixin
 */
/**
 * grid-cell mixin
 */
/**
 * grid-area mixin
 */
/**
 * grid mixin
 */
/**
 * Default grid mixin
 * $cols number of grid columns for cssgrid().
 * $colscount number of columns for IE11 hack. TODO debug why $cols cant be used.
 * $row-gap grid-row-gap value.
 * $col-gap grid-column-gap value.
 */
/**
 * Button variants
 *
 * Easily pump out default styles, as well as :hover, :focus, :active,
 * and disabled options for all buttons.
 */
/**
   $icon-name : Must NOT be  a string/wrapped in quotes.
   $fill-colour : expecting hex or rgb value.
**/
/**
 * Button link variants
 *
 * Easily pump out default styles, as well as :hover, :focus, :active,
 * and disabled options for all buttons.
 */
/**
 * Button tab
 */
/**
 * Button sizes.
 */
/**
 * UI.
 */
/**
 * Fill types.
 */
/**
 * Forms.
 */
/**
 * Component settings.
 */
/**
 * Base Fonts map.
 *
 * breakpoint: (font-size, line-height, leading-space, trailing-space)
 */
/**
 * Description.
 */
/**
 * Copy.
 */
/**
 * Settings.
 */
/**
 * To use CSS filters as the hover effect instead of opacity,
 * set $hamburger-hover-use-filter as true and
 * change the value of $hamburger-hover-filter accordingly.
 */
/**
 * Types (Remove or comment out what you don’t need)
 */
/**
 * Buttons
 *
 * For each of BASE's buttons,
 * define text, background and
 * border color.
 */
/**
 * Button sizes map
 * $padding-horizontal, $font-size, $border-radius, $grid-height-setting, $btn-border-size, $btn-margin, $btn-padding
 */
/**
 * Button fills map
 */
/**
 * Iconography.
 */
.centertext {
  text-align: center; }

.images {
  height: 100%;
  min-height: 250px;
  overflow: hidden;
  position: relative; }
  .images img {
    display: block;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%; }

.boxed {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19), 0 5px 6px 0 rgba(0, 0, 0, 0.05);
  grid-auto-rows: max-content; }

.padded {
  padding: 16px; }
  @supports (display: grid) {
    .padded {
      /** if cols or rows are numbers convert to fraction lists */
      /**
     * ie does not support grid gap - why we insert the gap space as a row or colum
     * note! the first loop is to ensure cols/rows are not a multidimensional list
     * (when generated by the repeat function)
     */
      display: -ms-grid;
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem; } }
  .padded::before, .padded::after {
    display: none; }

@media (min-width: 48em) {
  @supports (display: grid) {
    .layout {
      /** if cols or rows are numbers convert to fraction lists */
      /**
     * ie does not support grid gap - why we insert the gap space as a row or colum
     * note! the first loop is to ensure cols/rows are not a multidimensional list
     * (when generated by the repeat function)
     */
      display: -ms-grid;
      display: grid;
      grid-column-gap: 1rem;
      -ms-grid-columns: 1fr 1rem 1fr;
      grid-row-gap: 1rem;
      grid-template-columns: 1fr 1fr; } }
  .layout::before, .layout::after {
    display: none; }
  .layout > div {
    margin-bottom: 0; } }

.layout > div {
  margin-bottom: 16px; }

@supports (display: grid) {
  .buttons {
    /** if cols or rows are numbers convert to fraction lists */
    /**
     * ie does not support grid gap - why we insert the gap space as a row or colum
     * note! the first loop is to ensure cols/rows are not a multidimensional list
     * (when generated by the repeat function)
     */
    display: -ms-grid;
    display: grid;
    grid-column-gap: 1rem;
    -ms-grid-columns: 1fr 1rem 1fr;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr; } }

.buttons::before, .buttons::after {
  display: none; }

.footer {
  text-align: center; }

.banner {
  bottom: 16px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1; }
  .banner::before {
    border-top: 1px solid #999;
    content: '';
    position: absolute;
    top: -8px;
    width: 100%; }
  .banner::after {
    border-top: 1px solid #999;
    bottom: -8px;
    content: '';
    position: absolute;
    width: 100%; }

.video {
  position: relative; }

@supports (display: grid) {
  .bloggrid {
    /** if cols or rows are numbers convert to fraction lists */
    /**
     * ie does not support grid gap - why we insert the gap space as a row or colum
     * note! the first loop is to ensure cols/rows are not a multidimensional list
     * (when generated by the repeat function)
     */
    display: -ms-grid;
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem; } }

.bloggrid::before, .bloggrid::after {
  display: none; }

@media (min-width: 48em) {
  .bloggrid {
    margin: auto;
    max-width: 650px; } }

@media (min-width: 64em) {
  .bloggrid {
    max-width: 850px; } }
